"use client";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import { motion } from "framer-motion";
import {
  ChevronLeft,
  ChevronRight,
  Star,
  Award,
  Users,
  Settings,
} from "lucide-react";

// Testimonials data
const testimonials = [
  {
    text: "Infyways has played a crucial role in elevating our digital presence. The website development they delivered is not only visually appealing but also user-friendly, SEO-optimized, and efficient. We've seen a notable rise in our online engagement and organic traffic since its launch. Infyways is a trusted partner for businesses looking for professional web design and custom development services.",
    name: "Rajesh Sharma",
    position: "CEO of Tech Innovations, India",
    image: "/testimonials/rajesh-sharma.png",
    rating: 5,
  },
  {
    text: "The team at Infyways is truly exceptional. They grasped our vision and delivered a custom website that went beyond our expectations. Their website design services are top-notch, and the ongoing technical support and maintenance services have been outstanding. If you're looking for responsive web design, fast-loading websites, and a team that understands user experience (UX), Infyways is the one to trust.",
    name: "Laura Thompson",
    position: "Marketing Manager at Green Earth, Australia",
    image: "/testimonials/laura-thompson.png",
    rating: 5,
  },
  {
    text: "We've been collaborating with Infyways for over a year now. Their expertise in web development, eCommerce solutions, and SEO strategies is second to none. They've helped us improve our online visibility, and their commitment to delivering high-quality web design has been invaluable to our business growth. Highly recommend their services for custom web solutions.",
    name: "Richard Davis",
    position: "Director of Davis & Co., UK",
    image: "/testimonials/richard-davis.png",
    rating: 5,
  },
  {
    text: "Infyways is our trusted partner for all our web development needs. Their team is knowledgeable, approachable, and always ready to help. They offer custom website design, mobile optimization, and comprehensive digital solutions. We're incredibly pleased with the professional website they've built for us, which has enhanced our user engagement and conversion rates.",
    name: "Sophia Martinez",
    position: "Founder of Martinez Enterprises, Canada",
    image: "/testimonials/sophia-martinez.png",
    rating: 5,
  },
];

const TestimonialsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Calculate the indices of the two testimonials to show
  const firstTestimonialIndex = currentIndex;
  const secondTestimonialIndex = (currentIndex + 1) % testimonials.length;

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 2) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 2 + testimonials.length) % testimonials.length
    );
  };

  // Auto-scroll testimonials
  useEffect(() => {
    const interval = setInterval(() => {
      nextTestimonial();
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  // Render 5 stars based on rating
  const renderStars = (rating) => {
    return Array(rating)
      .fill(0)
      .map((_, i) => (
        <Star key={i} className="w-5 h-5 fill-yellow-400 text-yellow-400" />
      ));
  };

  // Function to clean testimonial text by removing markdown-style bold markers (**)
  const cleanTestimonialText = (text) => {
    return text.replace(/\*\*/g, "");
  };

  return (
    <section className="relative py-24 overflow-hidden">
      {/* Background with gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-950 to-blue-900">
        {/* Pattern overlay */}
        <div
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage:
              "radial-gradient(circle at 20% 90%, rgba(255, 255, 255, 0.4) 0%, transparent 20%), radial-gradient(circle at 90% 30%, rgba(255, 255, 255, 0.3) 0%, transparent 40%)",
            backgroundSize: "100% 100%",
          }}
        />
        {/* Dot pattern overlay */}
        <div
          className="absolute inset-0"
          style={{
            backgroundImage:
              "radial-gradient(rgba(255, 255, 255, 0.15) 1px, transparent 1px)",
            backgroundSize: "30px 30px",
            opacity: 0.3,
          }}
        />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-10">
          {/* Left column - Headings */}
          <div className="lg:col-span-4">
            {/* Section Label */}

            <div className="inline-flex items-center gap-2 bg-white/10 rounded-full px-4 py-2 mb-6">
              <span className="text-white animate-pulse">●</span>
              <span className="text-white text-xs md:text-sm">
                Our Testimonials
              </span>
            </div>

            {/* Section Heading */}
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-8">
              See What Our Clients <br />
              Are Saying About Their <br />
              Success with Us
            </h2>

            {/* Navigation Controls */}
            <div className="flex space-x-4 mb-10">
              <button
                onClick={prevTestimonial}
                className="w-12 h-12 bg-blue-900/50 hover:bg-blue-800/70 text-white flex items-center justify-center rounded-sm transition-colors"
                aria-label="Previous testimonials"
              >
                <ChevronLeft className="w-6 h-6" />
              </button>
              <button
                onClick={nextTestimonial}
                className="w-12 h-12 bg-blue-900/50 hover:bg-blue-800/70 text-white flex items-center justify-center rounded-sm transition-colors"
                aria-label="Next testimonials"
              >
                <ChevronRight className="w-6 h-6" />
              </button>
            </div>
          </div>

          {/* Right column - Testimonials */}
          <div className="lg:col-span-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* First Testimonial */}
              <motion.div
                key={`testimonial-${firstTestimonialIndex}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="bg-blue-600/20 backdrop-blur-sm p-8 rounded-lg border border-blue-800/50 relative"
              >
                {/* Quote mark */}
                <div className="absolute text-8xl text-white font-serif right-6 bottom-6">
                  &#8221;
                </div>

                <div className="flex flex-col h-full justify-between">
                  <div>
                    {/* Stars */}
                    <div className="flex mb-4">
                      {renderStars(testimonials[firstTestimonialIndex].rating)}
                    </div>

                    {/* Testimonial Text */}
                    <p className="text-white/90 text-lg mb-6 relative z-10">
                      {cleanTestimonialText(
                        testimonials[firstTestimonialIndex].text
                      )}
                    </p>
                  </div>

                  {/* Author Info */}
                  <div className="flex items-center">
                    <div className="w-12 h-12 mr-4 relative rounded-full overflow-hidden">
                      <Image
                        src={testimonials[firstTestimonialIndex].image}
                        alt={testimonials[firstTestimonialIndex].name}
                        fill
                        className="object-cover"
                      />
                    </div>
                    <div>
                      <h4 className="text-white font-medium">
                        {testimonials[firstTestimonialIndex].name}
                      </h4>
                      <p className="text-blue-300 text-sm">
                        {testimonials[firstTestimonialIndex].position}
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>

              {/* Second Testimonial */}
              <motion.div
                key={`testimonial-${secondTestimonialIndex}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className="bg-blue-900/40 backdrop-blur-sm p-8 rounded-lg border border-blue-800/50 relative"
              >
                {/* Quote mark */}
                <div className="absolute text-8xl text-white/20 font-serif right-6 bottom-6">
                  &#8221;
                </div>

                <div className="flex flex-col h-full justify-between">
                  <div>
                    {/* Stars */}
                    <div className="flex mb-4">
                      {renderStars(testimonials[secondTestimonialIndex].rating)}
                    </div>

                    {/* Testimonial Text */}
                    <p className="text-white/90 text-lg mb-6 relative z-10">
                      {cleanTestimonialText(
                        testimonials[secondTestimonialIndex].text
                      )}
                    </p>
                  </div>

                  {/* Author Info */}
                  <div className="flex items-center">
                    <div className="w-12 h-12 mr-4 relative rounded-full overflow-hidden">
                      <Image
                        src={testimonials[secondTestimonialIndex].image}
                        alt={testimonials[secondTestimonialIndex].name}
                        fill
                        className="object-cover"
                      />
                    </div>
                    <div>
                      <h4 className="text-white font-medium">
                        {testimonials[secondTestimonialIndex].name}
                      </h4>
                      <p className="text-blue-300 text-sm">
                        {testimonials[secondTestimonialIndex].position}
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Counter Section */}
     

      {/* Structured Data for SEO */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            testimonials.map((testimonial) => ({
              "@context": "https://schema.org",
              "@type": "Review",
              itemReviewed: {
                "@type": "Organization",
                name: "Infyways Solutions",
                url: "https://www.infyways.com",
              },
              reviewBody: testimonial.text.replace(/\*\*/g, ""),
              author: {
                "@type": "Person",
                name: testimonial.name,
              },
              reviewRating: {
                "@type": "Rating",
                ratingValue: "5",
                bestRating: "5",
              },
            }))
          ),
        }}
      />
    </section>
  );
};

export default TestimonialsSection;
